<div id="SubmissionErrors" class="alert alert-secondary mb-2" role="alert">
    <div *ngIf="!hasPreviousStep">
        <div *ngIf="!areReceiverSelected">
            <p>{{'You must select at least one recipient.'|translate}}</p>
        </div>
    </div>
    <div *ngIf="!hasNextStep">
        <div *ngIf="utilsService.isUploading(uploads)">
            <span>{{'Waiting for the file(s) to finish uploading.'|translate}}</span>
            <i class="fa-solid fa-sync gly-spin"></i>
        </div>
        <div *ngIf="show_steps_navigation_interface && !singleStepForm && (!areReceiverSelected || submissionForm.invalid)">
            <p>{{'The following steps have errors:'|translate}}</p>
            <ul>
                <li *ngIf="!areReceiverSelected" (click)="goToStep.emit(-1)">{{'Recipient selection'|translate}}</li>
            </ul>
            <ul *ngIf="submissionForm.invalid">
                <span *ngFor="let step of context.questionnaire.steps | orderBy:'order'; let index = index">
                    <li *ngIf="displayStepErrors(index)" class="err-link" (click)="goToStep.emit(index)">{{step.label}}</li>
                </span>
            </ul>
        </div>
    </div>
    <src-step-error [field_id_map]="field_id_map" [stepForms]=stepForms [submission]="submission" (goToStep)="goToStep.emit()" [stepForm]="stepForm" [navigation]="navigation" [displayStepErrors]="displayStepErrors"></src-step-error>
</div>