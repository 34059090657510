<div class="row">
    <div class="col-md-6">
        <input name="node.dataModel.languages_enabled" [(ngModel)]="nodeResolver.dataModel.languages_enabled" class="d-none" />
        <input name="node.dataModel.default_language" [(ngModel)]="nodeResolver.dataModel.default_language" class="d-none" />
        <div class="form-group">
            <label>{{ 'Languages' | translate }}</label>
            <button class="btn btn-sm btn-primary add-language-btn" (click)="toggleLangSelect()">
                <i class="fa-solid fa-plus"></i>
                <span>{{ 'Add' | translate }}</span>
            </button>
            <div>
                <br />
                <div id="LanguageAdder" class="row" *ngIf="showLangSelect">
                    <div class="col-md-12">
                        <ng-select [(ngModel)]="selected.value" [items]="nodeResolver.dataModel.languages_supported | filter:langNotEnabledFilter : false | filter:'name':selected.value" bindLabel="name" [searchable]="true" [placeholder]="'Search' | translate" (ngModelChange)="enableLanguage($event)">
                            <ng-template ng-option-tmp let-item="item">
                                <span [innerHTML]="item.name"></span>
                                <span>[{{ item.code }}]</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <br />
                <ul class="selection-list">
                    <li *ngFor="let lang_code of nodeResolver.dataModel.languages_enabled; let index = index">
                        <span class="action-btns">
                            <span *ngIf="lang_code === nodeResolver.dataModel.default_language" class="default-language">
                                <i class="fa-solid fa-check"></i>
                            </span>
                            <span *ngIf="lang_code !== nodeResolver.dataModel.default_language" class="non-default-language" data-toggle="tooltip" title="{{'Use as default' | translate}}" (click)="nodeResolver.dataModel.default_language = lang_code">
                                <i class="fa-solid fa-check"></i>
                            </span>
                            <span *ngIf="lang_code !== nodeResolver.dataModel.default_language" (click)="removeLang(index, lang_code)" data-toggle="tooltip" title="{{'Remove' | translate}}" class="remove-lang-btn">
                                <i class="fa-solid fa-times"></i>
                            </span>
                            <i *ngIf="lang_code === nodeResolver.dataModel.default_language" class="fa-solid fa-times disabled-icon"></i>
                        </span>
                        <label>{{ this.languageUtils.languages_supported[lang_code].name }} [{{ lang_code }}]</label>
                    </li>
                </ul>
            </div>
        </div>
        <div class="form-group">
            <button class="btn btn-primary" id="save_language" [disabled]="contentForm.invalid" (click)="updateNode()" type="submit">
                <i class="fa-solid fa-check"></i>
                <span>{{ 'Save' | translate }}</span>
            </button>
        </div>
    </div>
</div>